html,
body,
#root {
  background-color: #000;
  height: 100%;
  margin: 0;
  padding: 0;
  color: #fff;
  user-select: none !important;
  font-family: "Nunito Sans", sans-serif;
}
